import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
});

function SymbolDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const locationType = location.state?.type || "";
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("DATA", data);

	// Estados para los nuevos campos
	const [itemId, setItemId] = useState("");
	const [name, setName] = useState("");
	const [code, setCode] = useState("");
	const [derivMultiplicador, setDerivMultiplicador] = useState("");
	const [multiplicador2, setMultiplicador2] = useState("");
	const [derivMultiplicador2, setDerivMultiplicador2] = useState("");
	const [multiplicador3, setMultiplicador3] = useState("");
	const [derivMultiplicador3, setDerivMultiplicador3] = useState("");
	const [cierre, setCierre] = useState("");
	const [valorPip, setValorPip] = useState("");

	const { handleDelete, handleSubmit } = useHandleActionItem(data, "symbols");

	useEffect(() => {
		if (locationType === "edit" && data) {
			setItemId(data.ID || "");
			setName(data.Nombre || "");
			setCode(data.Codigo || "");
			setDerivMultiplicador(data.DerivMultiplicador || "");
			setMultiplicador2(data.Multiplicador2 || "");
			setDerivMultiplicador2(data.DerivMultiplicador2 || "");
			setMultiplicador3(data.Multiplicador3 || "");
			setDerivMultiplicador3(data.DerivMultiplicador3 || "");
			setCierre(data.Cierre || "");
			setValorPip(data["Valor Pip"] || "");
		}
	}, [locationType, data]);

	const validateForm = () => {
		console.log({
			name,
			code,
			derivMultiplicador,
			multiplicador2,
			derivMultiplicador2,
			multiplicador3,
			derivMultiplicador3,
			cierre,
			valorPip,
			id: itemId,
		});
		if (
			!name ||
			!code ||
			!derivMultiplicador ||
			!multiplicador2 ||
			!derivMultiplicador2 ||
			!multiplicador3 ||
			!derivMultiplicador3 ||
			!cierre ||
			!valorPip
		) {
			Toast.fire({
				icon: "error",
				title: "Todos los espacios son requeridos",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleDeleteForm = async (event, itemId) => {
		event.preventDefault();
		if (!validateForm()) return;
		const data = {
			id: itemId,
		};
		await handleDelete(data, "symbols");
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleSubmit(locationType === "add" ? "add" : "update", "symbols", {
			name,
			code,
			derivMultiplicador,
			multiplicador2,
			derivMultiplicador2,
			multiplicador3,
			derivMultiplicador3,
			cierre,
			valorPip,
			id: itemId,
		});
		navigate("/symbols");
	};

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{locationType === "add" ? "Agregar símbolo" : "Actualizar símbolo"}</h1>
				<InputText label={"Nombre"} placeholder={"Nombre del Símbolo"} value={name} setValue={setName} />
				<InputText label={"Código"} placeholder={"Código del Símbolo"} value={code} setValue={setCode} />
				<InputText label={"DerivMultiplicador"} placeholder={"100"} value={derivMultiplicador} setValue={setDerivMultiplicador} />
				<InputText label={"Multiplicador2"} placeholder={"2"} value={multiplicador2} setValue={setMultiplicador2} />
				<InputText label={"DerivMultiplicador2"} placeholder={"200"} value={derivMultiplicador2} setValue={setDerivMultiplicador2} />
				<InputText label={"Multiplicador3"} placeholder={"4"} value={multiplicador3} setValue={setMultiplicador3} />
				<InputText label={"DerivMultiplicador3"} placeholder={"400"} value={derivMultiplicador3} setValue={setDerivMultiplicador3} />
				<InputText label={"Cierre"} placeholder={"57.2"} value={cierre} setValue={setCierre} />
				<InputText label={"Valor Pip"} placeholder={"0.01747"} value={valorPip} setValue={setValorPip} />

				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{locationType === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/symbols")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>

					<button
						onClick={(event) => {
							handleDeleteForm(event, itemId);
						}}
						className="btn btn-outline-danger btn-lg">
						Eliminar
					</button>
				</div>
			</form>
		</div>
	);
}

export default SymbolDetails;
