import { useNavigate } from 'react-router-dom';
import { Card } from '../../../components/card/card.jsx';
import TableBody from '../../../components/table/TableBody.jsx';

function CopyTrading() {
  const navigate = useNavigate();

  const handleAddCopyTrading = (send) => {
    navigate('/copyTradingDetails', { state: send });
  };

  return (
    <div>
      <div className='d-flex align-items-center mb-3'>
        <div>
          <h1 className='page-header mb-0'>Copy Trading</h1>
        </div>
        <div className='ms-auto'>
          <button
            onClick={() => handleAddCopyTrading({ type: 'add' })}
            className='btn btn-outline-theme'
          >
            <i className='fa fa-plus-circle fa-fw me-1'></i> Agregar Copy
            Trading
          </button>
        </div>
      </div>

      <Card>
        <div className='tab-content p-4'>
          <div className='tab-pane fade show active' id='allTab'>
            <div className='input-group mb-4'>
              <div className='flex-fill position-relative'>
                <div className='input-group'>
                  <div
                    className='input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0'
                    style={{ zIndex: 1020 }}
                  >
                    <i className='fa fa-search opacity-5'></i>
                  </div>
                  <input
                    type='text'
                    className='form-control ps-35px'
                    placeholder='Buscar CopyTrading'
                  />
                </div>
              </div>
            </div>

            <TableBody table={'copyTrading'} />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default CopyTrading;
