export const menus = [
	{ is_header: true, title: "Navegación" },
	{ path: "/dashboard", icon: "bi bi-house", title: "Inicio" },
	// { path: "/accounts", icon: "bi bi-wallet2", title: "Cuentas" },
	{ path: "/symbols", icon: "bi bi-currency-exchange", title: "Simbolos" },
	// { path: "/products", icon: "bi bi-tags", title: "Productos" },
	// { path: "/purchases", icon: "bi bi-cart", title: "Compras" },
	// { path: "/deposits", icon: "bi bi-wallet2", title: "Depósitos" },
	// { path: "/withdrawals", icon: "bi bi-cash-stack", title: "Retiros" },
	// { path: "/openPositions", icon: "bi bi-credit-card", title: "Posiciones Abiertas" },
	// { path: "/ibs", icon: "fa fa-user-plus", title: "Gestion IBs" },
	// { path: "/positions", icon: "bi bi-bar-chart-line", title: "Historial" },
	// { path: "/reports", icon: "bi bi-file-earmark-text", title: "Reportes" },
	{ path: "/users", icon: "bi bi-people", title: "Usuarios" },
];

export const affiliateMenu = [{ path: "/panel", icon: "bi bi-cpu", title: "Inicio" }];
