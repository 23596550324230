import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { AppSettings } from "../../config/app-settings";

const LoginPage = () => {
	//eslint-disable-next-line
	const { login, currentUser } = useAuth();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const context = useContext(AppSettings);

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass("p-0");

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass("");
		};

		// eslint-disable-next-line
	}, []);
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const data = await login(email, password);
			data.status ? (data.role !== "affiliate" ? navigate("/dashboard") : navigate("/panel")) : setError("Credenciales inválidas");
		} catch (error) {
			setError("Error al iniciar sesión");
		}
	};

	return (
		<div className="login">
			<div className="login-content">
				<form onSubmit={handleSubmit}>
					{/* Agrega el logo aquí */}
					<div className="text-center mb-3">
						<img src="/assets/logo.png" alt="Logo" style={{ maxWidth: "300px" }} />
					</div>

					<h1 className="text-center">Trade AI Panel</h1>
					<div className="text-inverse text-opacity-50 text-center mb-4"></div>
					{error && <div className="alert alert-danger">{error}</div>}
					<div className="mb-3">
						<label className="form-label">
							Dirección de correo electrónico <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className="form-control form-control-lg bg-white bg-opacity-5"
							placeholder="Ingrese su correo electrónico"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<label className="form-label">
							Contraseña <span className="text-danger">*</span>
						</label>
						<input
							type="password"
							className="form-control form-control-lg bg-white bg-opacity-5"
							placeholder="Ingrese su contraseña"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<div className="form-check">
							<input className="form-check-input" type="checkbox" id="customCheck1" />
							<label className="form-check-label" htmlFor="customCheck1">
								Recuérdame
							</label>
						</div>
					</div>
					<button type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">
						Iniciar Sesión
					</button>
					<div className="text-center text-inverse text-opacity-50">
						¿No tienes una cuenta todavía? <Link to="/register">Regístrate</Link>.
					</div>
				</form>
			</div>
		</div>
	);
};

export default LoginPage;
