import React, { useEffect, useState } from "react";
import { Card, CardBody, CardExpandToggler } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import { apiUrl } from "../../config/config.js";
import { getChartOptions } from "../../components/dashboard/getChartOptions.js";
import MovementsLog from "../../components/dashboard/MovementsLog.jsx";

function Dashboard() {
	const [depositsAmount, setDepositsAmount] = useState(0);
	const [withdrawalsAmount, setWithdrawalsAmount] = useState(0);
	const [totalPaymentsCounts, setTotalPaymentsCounts] = useState(0);
	const [customersCounts, setCustomersCounts] = useState(0);
	const [customerList, setCustomersList] = useState([]);
	const [purchaseList, setPurchaseList] = useState([]);
	const [statsData, setStatsData] = useState();
	const [chartOptions, setChartOptions] = useState(getChartOptions());

	useEffect(() => {
		// fetchDashboardData();
	}, []);

	const fetchDashboardData = async () => {
		try {
			const response = await fetch(apiUrl + "/dashboardData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (response.status === 200) {
				let data = await response.json();
				setCustomersCounts(data.customersCount[0].count);
				setTotalPaymentsCounts(data.paymentsCount[0].count);
				setDepositsAmount(data.depositsSum[0].sum);
				setWithdrawalsAmount(data.withdrawalsSum[0].sum);
				setCustomersList(data.customerList.slice(-10));
				setPurchaseList(data.purchaseList.slice(-10));
			}
		} catch (error) {
			console.error("Error al realizar la solicitud:", error);
		}
	};

	useEffect(() => {
		fetch("/assets/data/dashboard/stats.json")
			.then((res) => res.json())
			.then((result) => {
				setStatsData(result);
			});
	}, []);

	const counts = {
		DEPÓSITOS: depositsAmount,
		RETIROS: withdrawalsAmount,
		PAGOS: totalPaymentsCounts,
		"NÚMERO DE CLIENTES": customersCounts,
	};

	return (
		<div>
			<div className="row">
				{statsData &&
					statsData.length > 0 &&
					statsData.map((stat, index) => (
						<div className="col-xl-3 col-lg-6" key={index}>
							<Card className="mb-3">
								<CardBody>
									<div className="d-flex fw-bold small mb-3">
										<span className="flex-grow-1">{stat.title}</span>
										<CardExpandToggler />
									</div>
									<div className="row align-items-center mb-2">
										<div className="col-7">
											<h3 className="mb-0">{counts[stat.title]}</h3>
										</div>
										<div className="col-5">
											<div className="mt-n2">
												<Chart
													type={stat.chartType}
													height={stat.chartHeight}
													options={chartOptions[stat.chartType]}
													series={stat.chartData}
												/>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					))}
				<MovementsLog title={"LISTA DE CLIENTES"} data={customerList} type={"customer"} />
				<MovementsLog title={"LISTA DE COMPRAS"} data={purchaseList} type={"purchase"} />
			</div>
		</div>
	);
}

export default Dashboard;
